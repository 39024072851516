import api from "../api";
// const token = localStorage.getItem('token');
const baseOptions = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    // Authorization: "Bearer " + token,
  },
};

class Interceptor {
  get(url, options) {
    return api.get(url, { ...baseOptions, ...options });
  }

  post(url, data, options) {
    return api.post(url, data, { ...baseOptions, ...options });
  }

  patch(url, data, options) {
    return api.patch(url, data, { ...baseOptions, ...options });
  }

  put(url, data, options) {
    return api.put(url, data, { ...baseOptions, ...options });
  }

  delete(url, options) {
    return api.delete(url, { ...baseOptions, ...options });
  }
}

export default Interceptor;
