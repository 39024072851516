import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/helper";
import api from "../../utils/api";
import { updateUserAfterPayment } from "../reducers/auth.reducer";

export const getPricingPlan = createAsyncThunk(
  "payment/plan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/payment/plan`);
      return response.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const getPaymentList = createAsyncThunk(
  "payment/list",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/payment/list/${userId}`);
      return response?.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const createOrders = createAsyncThunk(
  "payment/createOrder",
  async ({ amount, userId, discount, coupon, planId, subtotal, gstAmount, total }, { rejectWithValue }) => {
    try {
      
      const response = await api.post(`${BASE_URL}/payment/create-order`, {
        amount: amount,
        userId,
        discount,
        coupon,
        planId,
        subtotal, 
        gstAmount, 
        total
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPayment = createAsyncThunk(
  "payment/verifyPayment",
  async (paymentData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `${BASE_URL}/payment/verify-payment`,
        paymentData
      );
      await dispatch(updateUserAfterPayment(response));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkCouponCode = createAsyncThunk(
  "payment/checkCouponCode",
  async (data, { rejectWithValue }) => {
    try {
      const coupon = { couponCode: data };
      const response = await api.post(
        `${BASE_URL}/payment/check-coupon`,
        coupon
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// Merchant

export const getMerchantPricingPlan = createAsyncThunk(
  "payment/merchantPlan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/payment/merchant-plan`);
      return response.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const createOrdersForMerchant = createAsyncThunk(
  "payment/createOrderForMerchant",
  async ({ amount, userId, discount, coupon, planId, subtotal, gstAmount, total }, { rejectWithValue }) => {
    try {
      
      const response = await api.post(`${BASE_URL}/payment/merchant-create-order`, {
        amount: amount,
        userId,
        discount,
        coupon,
        planId,
        subtotal, 
        gstAmount, 
        total
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPaymentForMerchant = createAsyncThunk(
  "payment/verifyPaymentForMerchant",
  async (paymentData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `${BASE_URL}/payment/merchant-verify-payment`,
        paymentData
      );
      // await dispatch(updateUserAfterPayment(response));
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantPaymentList = createAsyncThunk(
  "payment/merchantPaymentList",
  async (userId, { rejectWithValue }) => {
    try { 
      const response = await api.get(`${BASE_URL}/payment/merchant-list/${userId}`);
      return response?.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

// For admin
export const getMerchantPaymentListByAdmin = createAsyncThunk(
  "payment/merchantPaymentListByAdmin",
  async (_, { rejectWithValue }) => {
    try { 
      const response = await api.get(`${BASE_URL}/payment/admin/merchant-list`);
      return response?.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const createOrdersForCampus = createAsyncThunk(
  "payment/createOrder",
  async ({ amount, userId, discount, planId, subtotal, gstAmount, total, checkout_id }, { rejectWithValue }) => {
    try {
      
      const response = await api.post(`${BASE_URL}/auth/create-order`, {
        amount: amount,
        userId,
        discount,
        planId,
        subtotal, 
        gstAmount, 
        total,
        checkout_id
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPaymentForCampus = createAsyncThunk(
  "payment/verifyPayment",
  async (paymentData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `${BASE_URL}/auth/verify-payment`,
        paymentData
      );
      await dispatch(updateUserAfterPayment(response));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);