import axios from "axios";
import { BASE_URL } from "./helper";

const api = axios.create({ baseURL: BASE_URL });

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        alert("Session expired! Please log in again.");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.clear(); // ✅ Clear all stored data
        sessionStorage.clear();
        window.location.href = "/signin";
        return Promise.reject(error);
      }

      try {
        const response = await axios.post(`${BASE_URL}/auth/refresh-token`, {
          token: refreshToken,
        });

        const newAccessToken = response.data.accessToken;
        localStorage.setItem("token", newAccessToken);

        error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(error.config); // Retry the failed request with the new token
      } catch (refreshError) {
        console.error("Token refresh failed!", refreshError);
        alert("Session expired! Please log in again.");
        localStorage.clear(); // ✅ Clear all stored data
        sessionStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/signin"; // Redirect to login if refresh fails
      }
    } else if (error.response && error.response.status === 403) {
      alert("Session expired! Please log in again.");
      localStorage.clear(); // ✅ Clear all stored data
      sessionStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/signin"; // Redirect to login
    }
    return Promise.reject(error);
  }
);

export default api;
